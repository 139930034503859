.disabled {
    opacity: .3;
    pointer-events: none;

    i {
        color: lighten(gray, 10); } }

.left {
    text-align: left !important; }

.centered {
    text-align: center !important; }

.right {
    text-align: right !important; }

.float-left {
    float: left;
    margin-right: $margin; }

.float-centered {
    display: block;
    margin: auto auto $margin auto; }

.float-right {
    float: right;
    margin-left: $margin; }

.strong {
    font-weight: bold;
    color: $color-primary; }

@media (max-width: $screen-tiny) {
    .float-left,
    .float-centered,
    .float-right {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        clear: both;
        float: none;
        margin-bottom: $margin; } }
