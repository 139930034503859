label {
    font-weight: bold;
    display: block;
    font-size: 12px;
    margin-bottom: $margin-s; }

.form-uploader,
input[type="text"],
input[type="email"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="password"],
select,
textarea {
    width: 100%;
    padding: $padding * 1.25 $padding;
    margin: 2px 0;
    border-radius: $radius;
    display: block;
    appearance: none;
    background: #f5f5f5;
    border: 1px solid darken($color-superlight, 8);

    &:hover {
        border-color: darken($color-superlight, 20); }

    &:focus {
        border-color: darken($color-light, 10);
        outline: none; }

    &.small {
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; }
        font-size: 10px;
        padding: $padding; } }

input[type="checkbox"] {
    vertical-align: top;

    &.right {
        float: right; } }

textarea {
    line-height: 1.6; }

select {
    background: #f5f5f5 no-repeat center right 15px url("data:image/svg+xml;base64,CjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDEwMDAgMTAwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwMCAxMDAwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPG1ldGFkYXRhPiBTdmcgVmVjdG9yIEljb25zIDogaHR0cDovL3d3dy5vbmxpbmV3ZWJmb250cy5jb20vaWNvbiA8L21ldGFkYXRhPgogIDxnPjxwYXRoIGQ9Ik04NzUuNywxNzkuOEw1MDAsNTc3LjlMMTI0LjMsMTc5LjhMMTAsMzAxbDQ5MCw1MTkuMkw5OTAsMzAxTDg3NS43LDE3OS44eiI+PC9wYXRoPjwvZz48L3N2Zz4KICA=");
    background-size: 10px;

    &::-ms-expand {
        display: none; } }

form h2 {
    margin-bottom: 15px; }

.form-block {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: $margin 20px 0;
    margin-bottom: $margin;
    margin-left: -5px;
    margin-right: -5px; }

.form-subtitle {
    font-size: 12px;
    color: $color-primary;
    text-transform: uppercase;
    text-align: center;

    // margin-bottom: 20px
    // background: white
    // margin-top: 15px

    // &:before
    //     display: block
    //     content: ''
    //     border-bottom: 1px dotted $color-light
    //     margin-bottom: -10px

    // &:after
    //     display: block
    //     content: ''
    //     background: white
    //     width: 85px
    //     height: 10px
    //     margin: auto auto
    //     background: white
 }    //     margin-top: -13px

.form-label {
    font-weight: normal;
    font-size: .9em; }

.form,
.form-row {
    margin-bottom: $margin; }

.form-required label:after {
    display: inline-block;
    content: '*';
    margin-left: 5px;
    color: $color-important; }

.form-hint {
    font-size: .8rem;
    color: $color-light;
    margin-top: $margin-s; }

.form-checkboxes,
.form-radios {
    input {
        margin-right: 10px;
        vertical-align: middle; }

    label {
        font-weight: normal;
        margin: $margin-s 0;
        text-transform: none; }

    label:after {
        content: ''; } }

.form-checkboxes-inline,
.form-radios-inline {
    label {
        display: inline-block !important;
        margin-right: 20px; } }

.form-buttons {
    margin-top: $margin; }

.form-error {
    label {
        color: $color-error; }

    input {
        border: 1px solid $color-error; } }

.form-uploader {
    text-align: center;
    position: relative;
    margin-bottom: 8px;

    span {
        padding: 5px; }

    input {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }

    input {
        opacity: 0;
        cursor: pointer; } }

input.form-error,
select.form-error {
    border: 1px solid $color-error;

    &:hover {
        border-color: $color-error; } }

.form-separator {
    border-bottom: 1px solid $color-superlight;
    margin-top: $margin;
    margin-bottom: $margin-l; }

.form-errors {
    margin: $margin-s 0;
    padding: 0;

    li {
        list-style: none;
        color: $color-error; } }

.form-flex {
    display: flex;

    .button {
        margin-right: 5px; }

    .button:last-child {
        margin-right: 0; } }

.form-checkboxes i {
    font-size: 1.8rem;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 10px;
    cursor: pointer;

    &.fa-toggle-off {
        color: $color-light; }

    &.fa-toggle-on {
        color: $color-primary; } }
