.trix-content {
    height: 350px;
    background: #f5f5f5; }

.richtext-simple {
    trix-toolbar {
        .icon.link,
        .block_tools {
            display: none !important; }

        // .strike
 } }        //     display: none
