.overlay,
.overlay-absolute {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5); }

.overlay {
    position: fixed;
    z-index: 200; }

.overlay-absolute {
    position: absolute;
    z-index: 100; }
