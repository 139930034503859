.button-rack {
    border: 1px solid $color-superlight;
    border-radius: $radius;
    display: inline-block;
    font-size: .9rem;
    position: absolute;
    top: 5px;
    right: 15px;
    color: $color-light;

    a {
        display: inline-block;
        padding: $padding-s $padding-l;
        border-right: 1px solid $color-superlight;

        &:last-child() {
            border-right: 0; }

        &.active {
            color: white;
            background: $color-primary;
            bottom: 0; } } }

.button,
.button-block {
    border: 1px solid $color-primary;
    padding: $padding $padding-l;
    background: white;
    cursor: pointer;
    text-align: center;
    display: block;
    width: 100%;
    border-radius: $radius;
    appearance: none;
    line-height: 1.5;
    vertical-align: middle;
    text-transform: uppercase;
    font-size: .9rem;
    outline: 0;

    i {
        margin-right: $margin-s;
        font-size: 1rem;
        vertical-align: center; } }

.button-action {
    background: #f5f5f5;
    border: 1px solid $color-light;

    i {
        margin: 0;
        font-size: 18px;
        vertical-align: center;
        color: $color-light; }

    &.active {
        border: 1px solid $color-primary;

        i {
            color: $color-primary; } } }

.button[disabled] {
    opacity: 0.7; }

.button-icon {
    i {
        margin: 0;
        vertical-align: middle; } }

.buttons {
    margin-bottom: $margin;

    .button,
    .button-block {
        margin-bottom: $margin-s;

        &:last-child {
            margin-bottom: 0; } } }

.button-primary {
    background: $color-primary;
    border-color: $color-primary;
    color: white !important; }

.button-secondary {
    background: $color-secondary;
    border-color: $color-secondary;
    color: white !important; }

.button-basic-primary {
    border-color: $color-primary;
    color: $color-primary !important; }

.button-basic-secondary {
    border-color: $color-secondary;
    color: $color-secondary !important; }

.button-small {
    font-size: .8rem;
    padding: 5px 10px; }

.button-file-action {
    min-width: 75px !important; }

.button-service-action {
    min-width: 115px !important; }

@media (min-width: $screen-small) {
    .buttons {
        .button {
            display: inline-block;
            width: auto; }

        .button {
            margin: 0 $margin-s / 2; }

        .button-block {
            display: block;
            width: 100%;
            margin: 0;
            margin-bottom: $margin-s; }

        &.left {
            .button:first-child {
                margin-left: 0; } }

        &.right {
            .button:last-child {
                margin-right: 0; } } } }
