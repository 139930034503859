// .card
//     border: 1px solid $color-superlight
//     padding: $padding-l
//     border-radius: $radius
//     background: white
//     margin-bottom: $margin

.card {
    background: rgba(white, .97);
    width: 100%;
    box-shadow: 0 2px 5px rgba(#000, .3);
    padding: 20px 30px;
    margin-bottom: 25px;
    position: relative;

    a {
        color: $color-secondary; } }

.card-compact {
    position: relative;
    display: flex;
    align-items: center;
    padding: $padding $padding-l; }

.card-item,
.card-item-wide {
    width: 100%;
    margin-bottom: $margin;
    height: auto; }

.card-item-wide {
    margin-left: -$padding-l;
    width: calc(100% + #{$padding-l * 2} );
    display: block;

    &:first-child {
        margin-top: -$padding-l; }

    &:last-child {
        margin-bottom: -$padding-l; } }

.card-separator {
    margin: $margin-l 0;
    margin-left: -$padding-l;
    width: calc(100% + #{$padding-l * 2} );
    display: block;
    border-bottom: 1px solid $color-superlight; }
