*, *:before, *:after {
    box-sizing: border-box;
    outline: none; }

// .fa
//     vertical-align: middle
//     border: 1px solid #000

html {
    font-size: 13px; }

body {
    line-height: $line-height;
    background: fixed #fff url($theme-background);
    background-size: cover;
    font-family: 'Roboto', sans-serif; }

a {
    color: inherit;
    text-decoration: none;
    transition: all .25s;

    &[disabled] {
      pointer-events: none;
      color: $color-error; } }

figure {
    margin: 0;
    padding: 0;
    width: 100%;
    display: block;
    margin-bottom: $margin;
    position: relative;

    img {
        width: 100%;
        height: auto;
        display: block; }

    figcaption {
        position: absolute;
        text-align: center;
        bottom: 0;
        width: 100%; } }

.pre {
    white-space: pre; }

hr {
    border: none;
    border-bottom: 1px dotted $color-light;
    margin: 20px 0; }

dl {
    width: 100%;
    margin-bottom: $margin-l;
    overflow: hidden; }

dt, dd {
    float: left;
    margin: 0;
    padding: 0; }

dt {
    font-weight: bold;
    width: 135px; }
dd {
    width: calc(100% - 135px); }
