.context {
    margin: $margin 0 $margin;
    display: flex;

    input,
    .button {
        margin: 0;
        padding: $padding * 1.25 $padding-l;
        height: 100%; } }

.context-wide {
    float: left;
    flex: 1;
    margin-right: $margin;

    input {
        background: #eee url(/img/icon-search.svg) no-repeat 10px center;
        background-size: 25px;
        padding-left: 50px; } }

.context-actions {
    float: right;
    text-align: right; }

.context-results-empty {
    border-top: 1px solid $color-superlight;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;

    .progressbar {
        margin-top: 10px; } }

