.loading-wheel-button {
    height: 24px;
    margin-right: 10px;
    vertical-align: middle;
    margin-top: -9px;
    margin-bottom: -8px; }

.loading-block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(white, .9);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    z-index: 9;
    flex-direction: column;

    p {
        text-align: center;
        margin: 0; } }

.loading-wheel {
    width: 35px;
    height: 35px;
    margin: 15px auto 0;
    opacity: .5; }

.loading {
    position: fixed;
    top: 0;
    // left: 0
    right: 200px;
    // bottom: 0
    margin-right: 35px;
    margin-top: 35px;
    background: rgba(white, .95);
    background-size: 64px 64px;
    z-index: 1000000;

    span {
        margin-top: -50px;
        position: absolute;
        top: calc(50% + 80px);
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        font-size: 10px; } }

.spinner {
    position: absolute;
    top: calc(50% - 30px);
    margin-left: -35px;
    left: 50%;
    width: 70px;
    height: 35px;
    text-align: center;
    font-size: 10px; }

.spinner > div {
    background-color: $color-primary;
    height: 100%;
    width: 6px;
    margin: 2px;
    display: inline-block;
    animation: sk-stretchdelay 1.2s infinite ease-in-out; }

.spinner .rect2 {
    animation-delay: -1.1s; }

.spinner .rect3 {
    animation-delay: -1.0s; }

.spinner .rect4 {
    animation-delay: -0.9s; }

.spinner .rect5 {
    animation-delay: -0.8s; }

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4); }

    20% {
        transform: scaleY(1.0); } }



//     img
//         width: 40px
//         position: absolute
//         top: calc(50% - 30px)
//         left: 50%
//         animation: spin 1s infinite linear

//     span
//         margin-top: -50px
//         position: absolute
//         top: calc(50% + 80px)
//         text-align: center
//         text-transform: uppercase
//         width: 100%
//         font-size: 10px

// @keyframes spin
//     from
//         transform: translateX(-50%) rotate(0deg)

//     to
//         transform: translateX(-50%) rotate(360deg)


