h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 0 0 .5rem 0;

    small {
        font-weight: normal;
        font-size: 75%; } }

h1 {
    font-size: 1.5rem;
    margin-bottom: $margin;
    color: $color-primary;
    text-transform: uppercase;
    font-weight: normal; }

h2 {
    font-size: 1.6rem; }

h3 {
    font-size: 1.4rem; }

h4 {
    font-size: 1.2rem;
    color: #888;
    font-weight: normal; }

h5 {
    font-size: 1.1rem; }

h6 {
    font-size: 1rem; }

ul, p {
    margin: 0;
    margin-bottom: $margin; }

ul {
    padding-left: 15px;

    ul {
        margin-bottom: 0;
        padding-left: 30px; } }

.lead {
    font-size: 1.25rem; }
