.navigation-banner {
    height: 175px;
    background: white url(/img/banner-gsma.png) center center;
    background-size: cover;
    box-shadow: 0 5px 5px rgba(black, .2);
    // border-bottom: 1px solid rgba(white, .5)
    margin-top: 50px;
    margin-bottom: -50px;
    position: relative;

    @if ($theme == 'cannes') {
        display: none; } }

.navigation {
    margin-top: 50px;
    background: lighten($color-primary, 5);
    box-shadow: 0 5px 5px rgba(black, .2);

    a {
        display: block;
        padding: 20px 30px 15px;
        color: white;

        i {
            margin-right: 10px; } }

    ul, li {
        padding: 0;
        margin: 0;
        list-style: none; }

    .active a {
        background: darken($color-primary, 10);
        font-weight: bold;
        padding-right: 35px;
        border-left: 1px solid darken($color-primary, 15);
        border-right: 1px solid darken($color-primary, 15); } }

ul.navigation-menu {
    display: inline-block;

    li {
        display: inline-block;
        position: relative;

        &:hover ul.navigation-submenu {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            transition-delay: 0s; } } }

.navigation-has-submenu > a:after {
    content: "▼";
    display: inline-block;
    font-size: 7px;
    margin-left: 8px;
    opacity: 1;
    color: black; }

ul.navigation-submenu {
    width: 150px;
    position: absolute;
    left: 0;
    background: #fff;
    border: 1px solid $color-superlight;
    box-shadow: 0 4px 2px rgba(black, .2);
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    transition: visibility 0s .10s, opacity .10s, transform .10s;
    font-size: .8rem;

    li {
        float: none;
        display: block;
        border-bottom: 1px solid $color-superlight; }

    a {
        color: black;
        text-align: left;

        &:hover {
            background: $color-hyperlight; } } }
