.label {
    background: #ccc;
    padding: 2px 8px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 11px;
    margin-top: 5px; }

.label-pending {
    background-color: #FFB74D; }

.label-pending-contract {
    background-color: #FF9800; }

.label-ok {
    background-color: #CDDC39; }

.label-signed {
    background-color: #2196F3;
    color: white; }
