.bubble {
    display: inline-block;
    margin-left: 10px;
    padding: 2px 7px;
    border-radius: 50%;
    background: #eee;
    border: 1px solid #ccc;
    color: $color-primary;
    font-weight: normal;
    text-align: center;
    font-size: 10px; }
