.menu {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    z-index: 0;

    ul {
        padding: 0; }

    li {
        &.active {
            font-weight: bold;

            a {
                color: $color-primary; } } }

    a {
        padding: $padding $padding-l;
        display: block;
        color: gray; } }

// Small Screens

@media (max-width: $screen-small - 1) {
    .menu {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0, 1, 0.5, 1);

        .menu-shown & {
            max-height: 1000px;
            transition: max-height 1s ease-in; }

        li {
            display: block;
            border-top: 1px solid $color-superlight;

            &.active {
                background: $color-hyperlight; } } }

    .menu-open,
    .menu-close {
        font-size: 1.25rem;
        text-align: center;
        display: block;
        margin-bottom: $margin; }

    .menu-close {
        display: none;
        border-top: 1px solid $color-superlight;
        border-bottom: 1px solid $color-superlight;

        .menu-shown & {
            display: block; } }

    .menu-open {
        display: block;
        border: 1px solid $color-superlight;

        .menu-shown & {
            display: none; } } }

// Big Screens

@media (min-width: $screen-small) {
    .menu {
        margin-bottom: $margin;

        li {
            display: inline-block;
            border-bottom: 2px solid $color-superlight;
            margin-right: $margin;

            &:last-child {
                margin-right: 0; }

            &.active {
                border-color: $color-primary; } } }

    .menu-open,
    .menu-close {
        display: none; } }

