
// Topbar

.topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 15px $inner-padding;
    text-align: right;
    border-bottom: 1px solid rgba(white, .3);
    box-shadow: 0 0 5px rgba(#000, .2);
    min-width: $min-width;
    z-index: 10;
    color: $theme-topbar-color;
    background: $theme-topbar-background;
    display: flex;

    // a
    //     color: #aaa

    //     &:hover
    //         color: #fff

    strong {
        font-weight: 500; } }

.topbar-logo {
    background: transparent url($theme-logo-small) center center no-repeat;
    background-size: 80%;
    width: 60px;
    height: 40px;
    margin-top: -8px;
    margin-bottom: -8px; }

.topbar-user {
    font-size: 12px;
    display: block;
    flex: 1;

    i {
        font-size: 18px;
        margin-left: 8px;
        vertical-align: middle; } }
