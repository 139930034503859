.progressbar {
    display: block;
    margin-bottom: $margin;
    height: $padding;
    border: 1px solid darken($color-hyperlight, 10);
    background: darken($color-hyperlight, 2);
    width: 100%;
    border-radius: $radius;
    padding: 1px; }

.progressbar-bar {
    border-radius: $radius;
    display: block;
    height: 100%;
    background: $color-neutral;
    width: 0;
    transition: .25s all ease-out; }

.progressbar-primary {
    .progressbar-bar {
        background: linear-gradient(to left, $color-secondary, $color-primary); } }

.progressbar-secondary {
    border-color: $color-secondary;

    .progressbar-bar {
        background: $color-secondary; } }

.progressbar-animated .progressbar-bar {
    background-image: linear-gradient(-45deg, transparent 0%, transparent 30%, rgba(white, .2) 30%, rgba(white, .2) 70%, transparent 70%);
    background-size: 25px 10px;
    animation: progressbar .5s linear infinite; }

@keyframes progressbar {
    from {
        background-position: 0 0; }
    to {
        background-position: 50px 0; } }

