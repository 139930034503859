.message {
    border-radius: $radius;
    border: 1px solid $color-light;
    background: $color-hyperlight;
    margin-bottom: $margin;
    padding: 1rem 1.5rem;
    position: relative;

    strong {
        color: $color-light; }

    .close {
        position: absolute;
        right: $padding-l;
        top: $padding;
        font-size: 1.25rem; } }

@each $type in $list-types {
    .message-#{nth($type, 1)} {
        background: nth($type, 3);
        border-color: nth($type, 2);

        .close,
        strong {
            color: nth($type, 2); } } }
