.modal,
.modal-absolute {
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: $padding-l;
    border-radius: $radius;
    background: white;
    overflow: hidden;
    @include shadow(3);

    .close {
        position: absolute;
        right: $padding-l;
        top: $padding;
        font-size: 1.25rem; }

    iframe {
        width: 100%;
        border: 0;
        display: block;
        min-height: 250px; } }

.modal-full {
    padding: 0;

    img {
        width: 100%;
        border: 0;
        display: block; } }

.modal {
    position: fixed;
    z-index: 250; }

.modal-absolute {
    position: absolute;
    z-index: 150; }

@media(max-width: $screen-tiny) {
    .modal,
    .modal-absolute {
        width: calc(100% - #{$margin * 2}); } }
