.header {
    padding: 10px $margin-l;
    position: relative;

    // top: 0
    // left: 0
    // right: 0
    z-index: 1000;
    background: rgba(white, .95);
    border-bottom: 1px solid $color-superlight;
    box-shadow: 0 5px 5px rgba(black, .1);

    .container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-with: $grid-container-tiny; } }

.header-logo {
    flex: 0;
    margin-right: $margin-l;

    img {
        width: 150px;
        display: block; } }

.header-auth {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    white-space: nowrap; }

.header-logout {
    color: $color-superlight;
    margin-left: 15px;
    font-size: 20px;
    transition: .5s color;

    &:hover {
        color: $color-primary; } }

.header-settings {
    color: $color-primary;
    margin-right: 50px;
    font-size: 24px; }

