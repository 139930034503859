$theme: 'gsma';

$theme-background: '/img/background-gsma-2018-2.png';
$theme-logo-big:   '/img/logo-gsma.png';
$theme-logo-small: '/img/logo-gsma-square.jpg';
$theme-logo-big-height: 150px;

$theme-topbar-background: black;
$theme-topbar-color: white;

$color-primary:         #AC0610;
$color-secondary:       #151D5A;
$color-tertiary:        #2DAAE0;
$color-quartery:        #1F4E5F;
