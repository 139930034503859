.ratio-1-1,
.ratio-4-3,
.ratio-16-9 {
    position: relative;
    background: $color-hyperlight;
    margin-bottom: $margin;
    overflow: hidden;
    width: 100%;

    &:before {
        content: "";
        display: block; }

    > *:first-child {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }

    img,
    iframe,
    video {
        height: 100%;
        width: 100%;
        object-fit: cover; } }

.ratio-1-1:before {
    padding-top: 100%; }

.ratio-4-3:before {
    padding-top: 75%; }

.ratio-16-9:before {
    padding-top: 56.25%; }
