.login-section {
    width: 400px;
    margin: 60px auto;
    background: linear-gradient(white 0%, rgba(white, .7));
    background: white;
    padding: 20px 40px;
    box-shadow: 0 0 50px rgba(black, .5);
    border-radius: 8px;

    label {
        color: black; } }

.login-logo {
    width: 100%;
    height: $theme-logo-big-height;
    background: transparent url($theme-logo-big) center center no-repeat;
    background-size: contain;
    display: block;
    margin: 20px auto 30px; }

.login-logo-name {
    text-align: center;
    margin-bottom: 35px;
    color: white;
    font-size: 11px; }

.login-button {
    margin-top: $margin * 2.1; }
