.planning-free {
    background-color: $color-planning-free;

    .planning-services & {
        cursor: default;
        background: repeating-linear-gradient(35deg, $color-planning-free, $color-planning-free 10px, darken($color-planning-free, 8) 10px, darken($color-planning-free, 8) 20px); } }

.planning-booked {
    background-color: $color-planning-booked;
    cursor: pointer !important; }

.planning-not-available {
    background: repeating-linear-gradient(35deg, darken($color-planning-free, 10), darken($color-planning-free, 10) 10px, darken($color-planning-free, 8) 10px, darken($color-planning-free, 8) 20px); }

.planning-my-booking {
    background-color: $color-planning-mybooking; }

.planning-table {
    td {
        padding: 8px 5px;
        height: 45px; }

    tbody th {
        font-weight: normal; } }

.planning-icon {
    background: white;
    padding-top: 1px;
    padding-left: 1px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    margin-right: 2px;
    display: inline-block;
    font-size: 11px;
    box-shadow: 0 1px 1px rgba(black, .3);

    i {
        display: inline-block;
        vertical-align: middle; }

    &:last-child {
        margin-right: 0; } }

.planning-legend-block {
    display: inline-block;
    width: 11px;
    height: 12px;
    margin-right: 10px;
    vertical-align: middle; }

.planning-legend {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #999;
    text-align: right;
    margin-right: 2px;

    li {
        margin-left: 20px;
        display: inline-block;
        font-size: 10px;
        text-transform: uppercase; }

    i {
        font-size: 13px;
        margin-right: 8px;
        color: #444; } }

.planning-table {
    border-color: rgba(black, .2);

    th, td {
        border-color: rgba(black, .2); } }

// $planning-border-color: rgba(#000, .1)
// $planning-row-height: 52px

// .center-fixed
//     display: flex
//     margin-bottom: 25px
//     align-content: flex-end

//     .button
//         width: 100px
//         display: block

//     .tabs
//         display: flex
//         flex: 1 1
//         justify-content: flex-end

//     .tab
//         padding: 10px 20px
//         cursor: pointer
//         background-color: white
//         text-transform: uppercase
//         font-size: .9em

//         &.selected
//             background-color: $color-primary
//             color: white

//         &:first-child
//             margin-right: -1px
//             border-top-left-radius: 20px
//             border-bottom-left-radius: 20px

//         &:last-child
//             margin-right: -1px
//             border-top-right-radius: 20px
//             border-bottom-right-radius: 20px

// .planning-icons-has
//     i
//         font-size: 14px

// .planning-icons
//     display: none

// .planning-icons-container:hover .planning-icons
//     display: block
//     position: absolute
//     background: white !important
//     padding: 10px
//     border-radius: 5px
//     box-shadow: 0 0 5px rgba(#000, .5)
//     margin-top: -26px
//     margin-left: -12px

//     i
//         margin: 0 5px

// .planning-empty
//     height: 200px
//     line-height: 200px
//     text-align: center

// .planning-container
//     border: 1px solid $planning-border-color
//     position: relative
//     overflow-x: scroll
//     overflow-y: hidden
//     padding-bottom: 20px

//     table
//         border-collapse: collapse
//         table-layout: fixed

//         th
//             padding: 2px

// .planning-inner
//     position: absolute
//     top: 0
//     left: 0
//     right: 0
//     bottom: 0
//     overflow-x: scroll

//     .free
//         background-color: $color-planning-free

//         .planning-services &
//             cursor: default
//             background: repeating-linear-gradient(35deg, $color-planning-free, $color-planning-free 10px, darken($color-planning-free, 8) 10px, darken($color-planning-free, 8) 20px)

//     .booked
//         background-color: $color-planning-booked

//     .not_available
//         background: repeating-linear-gradient(35deg, $color-planning-notavailable, $color-planning-notavailable 10px, darken($color-planning-notavailable, 4) 10px, darken($color-planning-notavailable, 4) 20px)

//     .my_booking
//         background-color: $color-planning-mybooking

//     .clickable
//         cursor: pointer

//         .left
//             font-size: 10px
//             float: left

//         .center
//             display: inline-block
//             position: relative
//             text-align: center
//             width: 100%

//             i
//                 font-size: 20px

//             small
//                 font-size: 13px
//                 margin-left: 10px
//                 background: white
//                 color: #333
//                 border-radius: 50%
//                 padding: 2px 5px
//                 margin-left: -2px
//                 display: inline-block
//                 text-align: center
//                 box-shadow: 0 0 10px rgba(black, .5)
//                 font-weight: bold

//         .right
//             font-size: 10px
//             float: right

// // Sidebar

// .planning-sidebar
//     background: white
//     position: relative
//     z-index: 5
//     width: 300px

//     table
//         width: 100%
//         border: 1px solid $planning-border-color
//         border-left: 0

//     th
//         height: 23px

//     td
//         border: 1px solid $planning-border-color
//         border-left: none
//         border-top: none
//         padding-right: 25px
//         text-overflow: ellipsis
//         white-space: nowrap
//         overflow: hidden
//         height: $planning-row-height
//         padding: 8px 10px
//         padding-right: 34px
//         position: relative
//         vertical-align: middle

//         &:first-child
//             border-top: 1px solid $planning-border-color

//         .right
//             margin: 0
//             position: absolute
//             right: 8px
//             // top: 9px

//         button.right
//             color: $color-primary
//             border: 1px solid $color-primary
//             background: white
//             padding: 2px 3px
//             border-radius: 3px
//             vertical-align: middle

//             &[disabled]
//                 opacity: .5
//                 color: $color-light
//                 border: 1px solid $color-light

//     tr:nth-child(even) td
//         background: #f5f5f5

// // Calendar

// .planning-calendar
//     padding-left: 299px

//     table
//         min-width: 100%

//     th
//         min-width: 70px
//         border: 1px solid $planning-border-color
//         border-bottom: 0
//         font-size: 12px

//         span
//             font-size: 10px

//     td
//         min-width: 70px !important
//         height: $planning-row-height
//         border: 1px solid $planning-border-color
//         vertical-align: middle !important

//     .my_booking
//         border-right: 1px solid transparent !important

//     .planning-meeting-space & .left,
//     .planning-meeting-space & .right
//         text-align: center !important
//         width: 100%
//         line-height: .3

//     .planning-services & .left,
//     .planning-services & .right
//         display: none

// // Summary

// .planning-summary
//     h2
//         color: $color-primary

//         i
//             color: $color-light
//             margin-left: 10px

//     [type="time"]
//         display: inline-block
//         width: 80px

// .planning-summary-icon
//     text-align: center

//     i
//         display: inline-block
//         color: $color-primary
//         margin-right: 10px
//         width: 12px
