html.ngdialog-open,
body.ngdialog-open {
    overflow: initial !important;
    width: 100%;
    overflow: hidden !important; }

.ngdialog-overlay {
    background: rgba(black, .8) !important;
    mouse-events: none; }

.ngdialog-close {
    border: none;
    background: none; }

.ngdialog {
    padding-top: 75px !important;
    height: auto;

    .card {
        box-shadow: none; }

    .section {
        background: white; }

    .ngdialog-content {
        background: white !important;
        padding: $padding-l * 2 $padding-l !important;
        box-shadow: 0 5px 50px rgba(black, .2);
        border: 1px solid black !important;
        border-radius: 0 !important; }

    .ngdialog-message {
        text-align: center;

        h2 {
            color: $color-primary;
            font-size: 1.4rem; }

        .buttons {
            margin: 0; } } }
