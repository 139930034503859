$sidebar-width: 250px;
$topbar-height: 80px;
$inner-padding: 25px;
$min-width: 0px;

$grid-container-tiny:   1140px;
$grid-container-small:  1140px;
$grid-container-medium: 1140px;
$grid-container-large:  1140px;

// $color-primary:         #0881A3
// $color-secondary:       #1F4E5F
// $color-tertiary:        #F4E7D3
// $color-quartery:        #F9F8ED

// $color-primary:         #03A9F4
// $color-secondary:       darken(#03A9F4, 5)
// $color-tertiary:        #0881A3
// $color-quartery:        #1F4E5F

$color-primary:         darken(#F92D17, 10);
$color-secondary:       #151D5A;
$color-tertiary:        #2DAAE0;
$color-quartery:        #1F4E5F;

$color-primary-light:  lighten($color-primary, 48);

$color-light:           #999;
$color-superlight:      #ccc;
$color-hyperlight:      #f7f7f7;
$color-ultralight:      #fafafa;

$color-deleted:         lightyellow;
$color-deleted-light:   lighten(lightyellow, 4);

$color-success:         desaturate(#9E9D24, 10);
$color-success-light:   lighten(#F9FBE7, 2);

$color-error:           desaturate(#B71C1C, 10);
$color-error-light:     lighten(#FFEEF0, 2);

$color-important:       desaturate(#B71C1C, 20);
$color-important-light: lighten(#FFEEF0, 2);

$color-warning:         desaturate(#E65100, 20);
$color-warning-light:   lighten(#FFF3E0, 2);

$color-info:            desaturate(#1A237E, 40);
$color-info-light:      lighten(#E8EAF6, 2);

$color-highlight:       lighten($color-primary, 52);
$color-highlight-light: lighten($color-primary, 54);

$color-neutral:         #37474F;
$color-neutral-light:   #FAFAFA;

$color-planning-free:           #7AA81E;
$color-planning-booked:         #FFCC00;
$color-planning-notavailable:   #bbb;
$color-planning-mybooking:      lighten($color-planning-booked, 15);

$color-table-success:           #7AA81E;
$color-table-important:         #D50000;
$color-table-warning:           #FFA000;
$color-table-none:              #DDDDDD;

$margin:                1.2rem;
$margin-l:              $margin * 2;
$margin-s:              $margin / 2;

$padding:               0.7rem;
$padding-l:             $padding * 2;
$padding-s:             $padding / 2;

$radius:                4px;
$line-height:           1.5;

$list-types: (deleted $color-deleted $color-deleted-light)  (success $color-success $color-success-light)  (error $color-error $color-error-light)  (important $color-important $color-important-light)  (warning $color-warning $color-warning-light)  (info $color-info $color-info-light) (highlight $color-highlight $color-highlight-light) (neutral $color-neutral $color-neutral-light);
