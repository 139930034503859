$screen-tiny:            576px !default;
$screen-small:           768px !default;
$screen-medium:          992px !default;
$screen-large:          1200px !default;

$grid-container-tiny:    540px !default;
$grid-container-small:   720px !default;
$grid-container-medium:  960px !default;
$grid-container-large:  1140px !default;

$grid-columns:              12 !default;
$grid-gutter-width:       30px !default;
$grid-gutter:             $grid-gutter-width / 2 !default;

$grid-include-pull:      true !default;
$grid-include-push:      true !default;
$grid-include-offset:    true !default;

// ---------

@mixin box-sizing() {
    box-sizing: border-box; }

@-ms-viewport {
    width: device-width; }

// CLEARFIX

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
    content: " ";
    display: table; }

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
    clear: both; }

// CONTAINER

.container {
    margin-right: auto;
    margin-left: auto; }

@media (max-width: $screen-small) {
    .container {
        padding-left: $grid-gutter;
        padding-right: $grid-gutter; } }

@media (min-width: $screen-small) {
    .container {
        width: $grid-container-small; } }

@media (min-width: $screen-medium) {
    .container {
        width: $grid-container-medium; } }

@media (min-width: $screen-large) {
    .container {
        width: $grid-container-large; } }

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: $grid-gutter;
    padding-right: $grid-gutter; }

// ROWS

.row {
    margin-left: -$grid-gutter;
    margin-right: -$grid-gutter; }

%all-col {
    @include box-sizing();
    position: relative;
    min-height: 1px;
    padding-left: $grid-gutter;
    padding-right: $grid-gutter; }

@for $i from 1 through $grid-columns {
    .tiny-#{$i},
    .small-#{$i},
    .medium-#{$i},
    .large-#{$i} {
        @extend %all-col; } }

// TINY

@media (max-width: $screen-small - 1) {
    %tiny-col {
        float: left; }

    @for $i from 1 through $grid-columns {
        .tiny-#{$i} {
            @extend %tiny-col;
            width: $i / $grid-columns * 100%; } }

    @if ($grid-include-pull) {
        .tiny-pull-0 {
            left: auto; }

        @for $i from 1 through $grid-columns {
            .tiny-pull-#{$i} {
                left: $i / $grid-columns * 100%; } } }

    @if ($grid-include-push) {
        .tiny-push-0 {
            right: auto; }

        @for $i from 1 through $grid-columns {
            .tiny-push-#{$i} {
                right: $i / $grid-columns * 100%; } } }

    @if ($grid-include-offset) {
        @for $i from 1 through $grid-columns {
            .tiny-offset-#{$i} {
                margin-left: $i / $grid-columns * 100%; } } } }

// SMALL

@media (min-width: $screen-small) {
    %small-col {
        float: left; }

    @for $i from 1 through $grid-columns {
        .small-#{$i} {
            @extend %small-col;
            width: $i / $grid-columns * 100%; } }

    @if ($grid-include-pull) {
        .small-pull-0 {
            left: auto; }

        @for $i from 1 through $grid-columns {
            .small-pull-#{$i} {
                left: $i / $grid-columns * 100%; } } }

    @if ($grid-include-push) {
        .small-push-0 {
            right: auto; }

        @for $i from 1 through $grid-columns {
            .small-push-#{$i} {
                right: $i / $grid-columns * 100%; } } }

    @if ($grid-include-offset) {
        @for $i from 1 through $grid-columns {
            .small-offset-#{$i} {
                margin-left: $i / $grid-columns * 100%; } } } }

// MEDIUM

@media (min-width: $screen-medium) {
    %medium-col {
        float: left; }

    @for $i from 1 through $grid-columns {
        .medium-#{$i} {
            @extend %medium-col;
            width: $i / $grid-columns * 100%; } }

    @if ($grid-include-pull) {
        .medium-pull-0 {
            left: auto; }

        @for $i from 1 through $grid-columns {
            .medium-pull-#{$i} {
                left: $i / $grid-columns * 100%; } } }

    @if ($grid-include-push) {
        .medium-push-0 {
            right: auto; }

        @for $i from 1 through $grid-columns {
            .medium-push-#{$i} {
                right: $i / $grid-columns * 100%; } } }

    @if ($grid-include-offset) {
        @for $i from 1 through $grid-columns {
            .medium-offset-#{$i} {
                margin-left: $i / $grid-columns * 100%; } } } }

// LARGE

@media (min-width: $screen-large) {
    %large-col {
        float: left; }

    @for $i from 1 through $grid-columns {
        .large-#{$i} {
            @extend %large-col;
            width: $i / $grid-columns * 100%; } }

    @if ($grid-include-pull) {
        .large-pull-0 {
            left: auto; }

        @for $i from 1 through $grid-columns {
            .large-pull-#{$i} {
                left: $i / $grid-columns * 100%; } } }

    @if ($grid-include-push) {
        .large-push-0 {
            right: auto; }

        @for $i from 1 through $grid-columns {
            .large-push-#{$i} {
                right: $i / $grid-columns * 100%; } } }

    @if ($grid-include-offset) {
        @for $i from 1 through $grid-columns {
            .large-offset-#{$i} {
                margin-left: $i / $grid-columns * 100%; } } } }

// VISIBILITY

.visible-tiny,
.visible-small,
.visible-medium,
.visible-large {
    display: none !important; }

.visible-tiny-block,
.visible-tiny-inline,
.visible-tiny-inline-block,
.visible-small-block,
.visible-small-inline,
.visible-small-inline-block,
.visible-medium-block,
.visible-medium-inline,
.visible-medium-inline-block,
.visible-large-block,
.visible-large-inline,
.visible-large-inline-block {
    display: none !important; }

// TINY

@media (max-width: $screen-small - 1) {
    .visible-tiny {
        display: block !important; }

    table.visible-tiny {
        display: table !important; }

    tr.visible-tiny {
        display: table-row !important; }

    th.visible-tiny,
    td.visible-tiny {
        display: table-cell !important; }

    .visible-tiny-block {
        display: block !important; }

    .visible-tiny-inline {
        display: inline !important; }

    .visible-tiny-inline-block {
        display: inline-block !important; }

    .hidden-tiny {
        display: none !important; } }

// SMALL

@media (min-width: $screen-small) and (max-width: $screen-medium - 1) {
    .visible-small {
        display: block !important; }

    table.visible-small {
        display: table !important; }

    tr.visible-small {
        display: table-row !important; }

    th.visible-small,
    td.visible-small {
        display: table-cell !important; }

    .visible-small-block {
        display: block !important; }

    .visible-small-inline {
        display: inline !important; }

    .visible-small-inline-block {
        display: inline-block !important; }

    .hidden-small {
        display: none !important; } }

// MEDIUM

@media (min-width: $screen-medium) and (max-width: $screen-large - 1) {
    .visible-medium {
        display: block !important; }

    table.visible-medium {
        display: table !important; }

    tr.visible-medium {
        display: table-row !important; }

    th.visible-medium,
    td.visible-medium {
        display: table-cell !important; }

    .visible-medium-block {
        display: block !important; }

    .visible-medium-inline {
        display: inline !important; }

    .visible-medium-inline-block {
        display: inline-block !important; }

    .hidden-medium {
        display: none !important; } }

// LARGE

@media (min-width: $screen-large) {
    .visible-large {
        display: block !important; }

    table.visible-large {
        display: table !important; }

    tr.visible-large {
        display: table-row !important; }

    th.visible-large,
    td.visible-large {
        display: table-cell !important; }

    .visible-large-block {
        display: block !important; }

    .visible-large-inline {
        display: inline !important; }

    .visible-large-inline-block {
        display: inline-block !important; }

    .hidden-large {
        display: none !important; } }

// PRINT

.visible-print,
.visible-print-block,
.visible-print-inline,
.visible-print-inline-block {
    display: none !important; }

@media print {
    .visible-print {
        display: block !important; }

    table.visible-print {
        display: table !important; }

    tr.visible-print {
        display: table-row !important; }

    th.visible-print,
    td.visible-print {
        display: table-cell !important; }

    .visible-print-block {
        display: block !important; }

    .visible-print-inline {
        display: inline !important; }

    .visible-print-inline-block {
        display: inline-block !important; }

    .hidden-print {
        display: none !important; } }
