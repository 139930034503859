.footer {
    padding: $margin 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    color: $color-superlight;
    margin: 0 auto; }

.users_track {
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 10px;
    background-color: rgba(0,0,0, 0.3); }

