.pagination {
    list-style: none;
    padding: 0;
    font-size: 0; // Remove unwanted spaces
    margin-bottom: $margin;

    li {
        padding: 0;
        font-size: .85rem;
        display: inline-block;
        border: 1px solid $color-superlight;
        border-right: 0;

        &:last-child {
            border-right: 1px solid $color-superlight; } }

    a {
        display: block;
        padding: $padding $padding * 1.5;
        text-align: center;
        text-decoration: none; }

    .disabled {
        a {
            opacity: .4;
            pointer-events: none; } }

    .active {
        background: $color-primary;
        color: white; } }
